// //for IE, Edge polyfill
import smoothscroll from 'smoothscroll-polyfill';
import objectFitImages from 'object-fit-images';
objectFitImages();
smoothscroll.polyfill();

import './_fonts.js';
import './_scroll-management.js';
import './_global-nav.js';
import './_add-index.js';
import './_calendar.js';
