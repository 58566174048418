import { library, icon, dom } from '@fortawesome/fontawesome-svg-core';
import { faTwitter,faInstagram,faFacebook } from '@fortawesome/free-brands-svg-icons';

library.add(faTwitter,faInstagram,faFacebook);
dom.i2svg();

const ssIcon = document.createElement('link');
ssIcon.rel = 'stylesheet';
ssIcon.href = '/wp-content/themes/kamoeartcenter_v3/fonts/ss-glyphish-outlined.css';
ssIcon.onload = function () {
  document.body.classList.add('loaded-community-icons');
};
const ssMenu = document.createElement('link');
ssMenu.rel = 'stylesheet';
ssMenu.href = '/wp-content/themes/kamoeartcenter_v3/fonts/ss-glyphish-filled.css';
ssMenu.onload = function () {
  document.body.classList.add('loaded-community-icons');
};

document.body.appendChild(ssIcon);
document.body.appendChild(ssMenu);

(function(d) {
  var config = {
    kitId: 'epf6eau',
    scriptTimeout: 3000,
    async: true
  },
  h=d.documentElement,
  t=setTimeout(function(){
    h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},
    config.scriptTimeout),
    tk=d.createElement("script"),
    f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';
    tk.async=true;
    tk.onload=tk.onreadystatechange=function(){
      a=this.readyState;
      if(f||a&&a!="complete"&&a!="loaded")return;
      f=true;clearTimeout(t);
      try{Typekit.load(config)}catch(e){}
    };
    s.parentNode.insertBefore(tk,s)
})(document);
