import ScrollOut from 'scroll-out';

ScrollOut();

ScrollOut({
  targets: ".banner--keisukemuseum__label",
  easing: 'easeOutExpo',
  onShown: function (element, ctx, scrollingElement) {
    document.body.classList.add('site-footer-in');
  },
  onHidden: function (element, ctx, scrollingElement) {
    document.body.classList.remove('site-footer-in');
  },
});

ScrollOut({
  targets: ".header-cover__bg",
  onShown: function (element, ctx, scrollingElement) {
    document.body.classList.remove('page-nav-fix');
  },
  onHidden: function (element, ctx, scrollingElement) {
    document.body.classList.add('page-nav-fix');
  },
});
