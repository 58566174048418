const globalNav = document.querySelector('.global-nav');
let globalNavItems = document.querySelectorAll('.global-nav__lists li');
    globalNavItems = Array.prototype.slice.call(globalNavItems, 0);
const globalNavMenu = document.querySelector('.global-nav__menu-label');

const open_label = 'menu';
const open_icon = 'ss-menu';
const close_label = 'close';
const close_icon = 'ss-plus';

globalNavMenu.addEventListener('click', function (e) {
  if (document.body.classList.contains('menu-open')) {
    document.body.classList.remove('menu-open');
    this.classList.replace(close_icon, open_icon);
    this.querySelector('span').innerText = open_label;
  } else {
    document.body.classList.add('menu-open');
    this.classList.replace(open_icon, close_icon);
    this.querySelector('span').innerText = close_label;
  }
});

globalNav.addEventListener('click', function (e) {
  if (
    e.target.innerText == close_label ||
    e.target.classList.contains('menu-item-has-children')
  ) {
    return this;
  }

  //force close
  document.body.classList.remove('menu-open');
  globalNavMenu.classList.replace(close_icon, open_icon);
  globalNavMenu.querySelector('span').innerText = open_label;
});

globalNavItems.forEach(function (item, index) {
  if(item.firstElementChild === null) return;
  item.firstElementChild.addEventListener('focus', function (e) {
    this.parentElement.parentElement.classList.add('focus');
  });
  item.firstElementChild.addEventListener('blur', function (e) {
    this.parentElement.parentElement.classList.remove('focus');
  });
});
