import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

document.addEventListener('DOMContentLoaded', function() {
  const calendarEl = document.getElementById('calendar');
  if(!calendarEl) return this;

  let calendar = new Calendar(calendarEl, {
    plugins: [ timeGridPlugin, dayGridPlugin, googleCalendarPlugin ],
    // defaultView: 'timeGridWeek',
    googleCalendarApiKey: "AIzaSyAI_MdwQfA5_m5WEe-dFdggva--eqUWHK8",
    height: 600,
    header:{
      left:"title",
      center:"",
      right:"prev,next today dayGridMonth,timeGridWeek"
    },
    minTime: '08:00:00',
    maxTime: '22:00:00',
    scrollTime: '08:00:00',
    allDaySlot: false,
    allDayText:"",
    locale:"ja",
    buttonText: {
      today:    '今日',
      month:    '月',
      week:     '週',
      day:      '日',
      list:     'リスト'
    },
    eventClick: function(info){
      info.jsEvent.preventDefault();
      const day = (info.event.start.getMonth()+1)+'月'+info.event.start.getDate()+'日';
      const start = info.event.start.getHours()+':'+(info.event.start.getMinutes() == 0 ? '00' : info.event.start.getMinutes());
      const end = info.event.end.getHours()+':'+(info.event.end.getMinutes() == 0 ? '00' : info.event.end.getMinutes());
      return alert( info.event.title + '号室は' + day + 'の' + start + 'から' + end + 'まで予約されています。');
    },
    eventSources: [
      {
        title:"101",
        className:"room101",
        googleCalendarId:"5kvj72iss2s4crh6strgru5tkc@group.calendar.google.com",
        backgroundColor:"#fff100",
        textColor:"#333333"
      },{
        title:"102",
        className:"room102",
        googleCalendarId:"5d8dpia7l1tuqnr6pli01288nk@group.calendar.google.com",
        backgroundColor:"#ed705b",
        textColor:"#ffffff"
      },{
        title:"103",
        className:"room103",
        googleCalendarId:"43p6mpk1aj7ofms443oir2t6fs@group.calendar.google.com",
        backgroundColor:"#ef8fb9",
        textColor:"#ffffff"
      },{
        title:"104",
        className:"room104",
        googleCalendarId:"2pk5475b3ip8j1cqnnvhdsrsuc@group.calendar.google.com",
        backgroundColor:"#f4a69c",
        textColor:"#ffffff"
      },{
        title:"201",
        className:"room201",
        googleCalendarId:"5pgvlf5a683q5nlsmol79chkno@group.calendar.google.com",
        backgroundColor:"#009944",
        textColor:"#ffffff"
      },{
        title:"206",
        className:"room206",
        googleCalendarId:"v5pi45oncgropagh60fgs90koo@group.calendar.google.com",
        backgroundColor:"#0076c3",
        textColor:"#ffffff"
      },{
        title:"301",
        className:"room301",
        googleCalendarId:"lmf7lhbnb4tqq38dt2284tf95o@group.calendar.google.com",
        backgroundColor:"#00a0e9",
        textColor:"#ffffff"
      }
    ],
  });

  calendar.render();
});
